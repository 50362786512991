import { Link } from "react-router-dom";
import logo from '../../assets/Logo_navbar.png';
import logoScrolled from '../../assets/Logo-umg-scrolled.png';
import logo1 from '../../assets/logo_białe.png';
import logoScrolled1 from '../../assets/logo_szare.png';
import "../Navbar/navbar.style.css";
import useAPI from "../../hooks/UseAPI";
import { useEffect, useState } from "react";
<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap" rel="stylesheet"></link>

interface Props {
  isScrolled: boolean;
}

const Navbar = ({ isScrolled }: Props) => {
  const navbarClasses = isScrolled ? "navbar scrolled" : "navbar notscrolled";
  const buttonClasses = isScrolled ? "buttonlog scrolled" : "buttonlog notscrolled";
  const token = sessionStorage.getItem("jwtToken");
  const [showAdminOptions, setShowAdminOptions] = useState(false);
  const [sessionTimeLeft, setSessionTimeLeft] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 990);

  const tokenValidate = useAPI({
    url: "user/validatetoken",
    method: "GET",
    auth: true,
    token: token ? token : "",
  });

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (token) {
      tokenValidate.call().then((res) => {
        if (res?.response !== undefined && res.response.ok) {
          setShowAdminOptions(true);
          const tokenParts = token.split('.');
          const payload = JSON.parse(atob(tokenParts[1]));
          const expirationTime = payload.exp * 1000;
          const timeLeft = Math.floor((expirationTime - Date.now()) / 1000);
          setSessionTimeLeft(timeLeft > 0 ? timeLeft : 0);
        } else {
          setShowAdminOptions(false);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showAdminOptions && sessionTimeLeft !== null && !isMobile) {
      timer = setInterval(() => {
        setSessionTimeLeft(prev => {
          if (prev === null || prev <= 0) {
            clearInterval(timer);
            handleLogout();
            return null;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showAdminOptions, sessionTimeLeft, isMobile]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} MINUT`;
  };

  return (
      <div className={navbarClasses}>
        <nav>
          <Link to="/" className="div930213">
            <img className="logo" src={isScrolled ? logoScrolled1 : logo1} alt="Logo" />
          </Link>

          <div className="text">
            <Link to="/">UNIWERSYTET MORSKI W GDYNI</Link>
          </div>

          <div className="navbar right">
            {showAdminOptions && (
                <>
                  {!isMobile && sessionTimeLeft !== null && (
                      <span className={buttonClasses}>
                  {formatTime(sessionTimeLeft)}
                </span>
                  )}
                  <button className={buttonClasses} onClick={handleLogout}>
                    WYLOGUJ
                  </button>
                </>
            )}
            {!showAdminOptions && (
                <div className="navbar-mobile-container">

                  <Link to="/notifications" className="sprawdz-zapisy">
                    WŁĄCZ POWIADOMIENIA
                  </Link>
                  <Link to="/checkregistration" className="sprawdz-zapisy">
                    MOJE ZAPISY
                  </Link>
                </div>
            )}
          </div>
        </nav>
      </div>
  );
};

export default Navbar;